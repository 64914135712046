<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="List" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('report.group_share/add')" addName="新增" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.name" size="small" clearable placeholder="按名称查询">
				</el-input>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="title" title="项目名称" align="center" />
			<vxe-table-column slot="table-item" field="group_name" title="电站名称" align="center" />
			<vxe-table-column slot="table-item" field="status" title="抽成占比分配" align="center">
				<template v-slot="{row}">
					<template v-for="(item) in row.percent">
						{{ item.N }}占{{ item.P }}%,
					</template>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="income" title="累计营收（元）" align="center" />
			<vxe-table-column slot="table-item" field="get_ele" title="累计提现充电金额（元）" align="center" />
			<vxe-table-column slot="table-item" field="get_occupy" title="累计提现占用金额（元）" align="center" />
			<vxe-table-column slot="table-item" field="ctime" title="创建时间" align="center" />
			<vxe-table-column slot="table-item" title="操作" align="center" width="250px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('report.group_share/edit')">编辑</el-button>
					<el-button size="small" plain @click="viewOne(row)" v-if="$hasAccess('report.group_share/settlelist')">查看详情</el-button>
					<el-button size="small" plain @click="deleteOne(row)" v-if="$hasAccess('report.group_share/del')">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 新增/编辑 -->
		<el-dialog title="编辑/新增共建项目" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="700px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<el-form-item label="名称" prop="title">
					<el-input v-model="editDialogFormData.title" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="分成配置" prop="percent">
					<el-button type="primary" size="small" plain icon="el-icon-plus" @click="itemAdd()"></el-button>
					<div v-for="(v,index) in editDialogFormData.percent">
						分成人 <el-select v-model="editDialogFormData.percent[index].U" placeholder="请选择" size="small" @change="changeUser(index, $event)">
							<el-option v-for="(item,k) in userList" :key="k" :label="item.realname" :value="item.user_id"></el-option>
						</el-select>
						占比 <el-input-number type="number" :min="0" :max="100" :step="1" :precision="0" v-model="editDialogFormData.percent[index].P" class="input" size="small"></el-input-number>
					</div>
				</el-form-item>
				<el-form-item label="场站" prop="group_id">
					<el-select v-model="editDialogFormData.group_id" placeholder="请选择场站" :disabled="editDialogFormData.ctime?true:false">
						<el-option v-for="(item,k) in groupList" :key="k" :label="item.group_name" :value="item.group_id"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import upload from '@/components/upload'
	export default {
		name: 'report-group-finance',
		components: {
			Tables,upload
		},
		data() {
			return {
				// 表格信息
				tableName: '共建管理',
				isLoading: false,
				totalPage: 0,
				searchFormData: {},
				List: [],
				groupList: [],
				userList:[],
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'change'
					}],
					group_id: [{
						required: true,
						message: '请输入选择场站',
						trigger: 'change'
					}],
					percent: [{
						required: true,
						message: '请编辑分成信息',
						trigger: 'change'
					}],
				},
				// 编辑
				editDialogShow: false,
				editDialogFormData: {percent:[]},
			}
		},
		computed: {
		},
		mounted() {
			//this.getList();
			this.getGroup();
		},
		activated() {},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Report.GroupShare.list(params);
				this.List = res.data;
				this.userList = res.users;
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 重置form
			resetFormData() {
				this.editDialogFormData = {percent:[]};
			},
			// 新增
			addOne() {
				this.resetFormData();
				this.editDialogShow = true;
			},
			// 编辑
			async editOne(row) {
				this.resetFormData();
				this.editDialogFormData = Object.assign({}, row);
				delete this.editDialogFormData._XID;
				for(let u of this.editDialogFormData.percent){
					u.U = u.U|0;
				}
				this.editDialogShow = true;
			},
			// 删除
			deleteOne(row) {
				this.$confirm('确定要删除该记录吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						id: row.id
					}
					await this.$api.Report.GroupShare.del(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.editDialogShow = false;
				this.$refs.xTable.refreshTable();
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate(async valid => {
					//console.log('valid', valid)
					if (valid) {
						for(let u of this.editDialogFormData.percent){
							u.U = u.U.toString();
						}
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.editDialogFormData)
						}
						if(this.editDialogFormData.ctime){
							await this.$api.Report.GroupShare.edit(params);
						}else{
							await this.$api.Report.GroupShare.add(params);
						}
						this.$notify({
							title: '成功',
							message: '操作成功',
							type: 'success'
						})
						this.closeDialog();
					}
				})
			},
			itemAdd(){ //添加分成用户
				this.editDialogFormData.percent.push({});
			},
			changeUser(i, e){ //修改用户
				console.log(i, e, this.editDialogFormData.percent[i].U);
				//this.editDialogFormData.percent[i].U = e.user_id;
				for(let u of this.userList){
					if(u.user_id == this.editDialogFormData.percent[i].U){
						this.editDialogFormData.percent[i].N = u.realname;
						break;
					}
				}
			},
			viewOne(row){ //结算管理
				this.$router.push({
					name: "report-group-settlelist",
					query: {group_id:row.group_id},
				});
			},
			getGroup(){ //获取场站
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 100,
				}
				this.$api.Device.GetGroupMinList(params).then((res)=>{
					this.groupList = res;
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
</style>
